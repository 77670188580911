import React, { Component } from "react";
import ls from "../../../Localization";

export class NavBar extends Component {
  render() {
    return (
      <div className="navbar navbar-custom" role="navigation">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" /> <span className="icon-bar" />
              <span className="icon-bar" />
            </button>

            <a className="navbar-brand logo" href="/">
              <img src="/img/logo.png" height="50" alt="Connecty Me" />
            </a>
          </div>
          <div className="navbar-collapse collapse" id="navbar-menu">
            <ul className="nav navbar-nav navbar-right">
              <li className="active">
                <a href="#home" className="nav-link">
                  {ls.about}
                </a>
              </li>
              <li>
                <a href="#features" className="nav-link">
                  {ls.features}
                </a>
              </li>
              <li>
                <a href="#getapp" className="nav-link">
                  {ls.download}
                </a>
              </li>
              <li>
                <a href="#contact" className="nav-link">
                  {ls.contact}
                </a>
              </li>
              <li>
                <a
                  href="https://dash.connecty.me"
                  target="_blank"
                  className="btn btn-white-bordered navbar-btn"
                >
                  Restaurante
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default NavBar;
