import React from "react";

import NavBar from "./NavBar";
import SubHome from "./Home";
import Features from "./Features";
import MoreFeatures from "./MoreFeatures";
import Download from "./Download";
import Contact from "./Contact";
import Footer from "./Footer";

const Home = () => {
  return (
    <div>
      <NavBar />
      <SubHome />
      <Features />
      <MoreFeatures />
      <Download />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
