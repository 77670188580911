import React from "react";

import ls from "../../../Localization";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-3">
            <a className="navbar-brand logo" href="/">
              <img src="/img/logo.png" height="50" alt="Connecty Me" />
            </a>
          </div>
          <div
            className="col-lg-4 col-md-4 text-center"
            style={{ color: "white" }}
          >
            <p>
              Todos os direiros reservados &copy;
              {new Date().getFullYear()}
            </p>
            <p>
              Desenvolvido por
              <a
                style={{ color: "white" }}
                href="https://www.loop3.com.br/?utm_source=ConnectyMe&utm_medium=footer"
                target="_blank"
                rel="noopener noreferrer"
              >
                LOOP3
              </a>
            </p>
          </div>
          <div className="col-lg-4 col-md-3">
            <ul className="nav navbar-nav">
              <li className="active">
                <a href="#home" className="nav-link">
                  {ls.about}
                </a>
              </li>
              <li>
                <a href="#features" className="nav-link">
                  {ls.features}
                </a>
              </li>
              <li>
                <a href="#getapp" className="nav-link">
                  {ls.download}
                </a>
              </li>
              <li>
                <a href="#contact" className="nav-link">
                  {ls.contact}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-2">
            <ul className="social-icons">
              <li>
                <a
                  href="https://www.facebook.com/connectymebr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/connecty.me"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/5547992052220?text=Ol%C3%A1%2C%20estou%20interessado%20no%20sistema%20e%20aplicativo%20Connecty.Me"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-whatsapp" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
