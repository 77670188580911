const strings = {
  appName: "Connecty.Me",
  about: "Sobre",
  features: "Recursos",
  download: "Baixar",
  contact: "Contato",
  name: "Nome",
  email: "E-mail",
  subject: "Assunto",
  sendMessage: "Enviar",
  sendMessageSuccess: "Sucesso! Sua mensagem foi enviada",
  sendMessageError:
    "Oops! Ocorreu um erro ao tentar enviar sua mensagem, tente novamente",
  signUp: "Cadastrar",
  signIn: "Entrar",
  loginIn: "Entrar em",
  forgotPassword: "Esqueceu sua Senha",
  dontHaveAnAccount: "Não tem uma conta",
  required: "Obrigatório",
  "Invalid email.": "E-mail não cadastrado",
  "Invalid password.": "Senha incorreta"
};

export default strings;
