import React from "react";

const Download = () => {
  return (
    <section id="getapp" className="section bg-img-1">
      <div className="bg-overlay" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="title" style={{ color: "white", fontSize: "5rem" }}>
              Baixar
            </h1>
            <div className="divider" />
            <p className="text-muted sub-title">
              Baixe na loja do seu celular!
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="platforms  col-xs-6 col-xs-offset-3 col-sm-3 col-sm-offset-3 col-md-2 col-md-offset-4">
              <a href="https://play.google.com/store/apps/details?id=me.connecty.connectyme">
                <img
                  src="/home/img/playstore_badge.png"
                  height="5"
                  className="img-responsive img-center2"
                  alt="PlayStore"
                />
              </a>
            </div>
            <div className="platforms col-sm-3 col-md-2 col-xs-6 col-xs-offset-3 col-sm-offset-0">
              <a href="https://itunes.apple.com/us/app/connecty-me-chame-o-gar%C3%A7om/id1267822016">
                <img
                  src="/home/img/applestore_badge.png"
                  height="5"
                  className="img-responsive img-center2"
                  alt="AppStore"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Download;
