import React from "react";

const MoreFeatures = () => {
  return (
    <section className="section" id="morefeatures">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h3 className="title">Muito mais</h3>
            <p className="text-muted sub-title">
              Connecty Me está sempre melhorando e adicionando novos recursos,
              siga-nos em nossas redes sociais e fique no topo das notícias.
              <br />
              Você também pode nos enviar sugestões clicando aqui, teremos o
              maior prazer em receber suas sugestões.
            </p>
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-4 col-sm-4 features"
            style={{ marginTop: 130 }}
          >
            <div className="media text-right feature">
              <span className="pull-right">
                <i className="far fa-calendar fa-2x" />
              </span>
              <div className="media-body">
                <h3 className="media-heading">Reserva de Mesas</h3>
                Sistema de reserva de mesas para restaurantes e clientes.
              </div>
            </div>
            <div className="media text-right feature">
              <span className="pull-right">
                <i className="fas fa-dollar-sign fa-2x" />
              </span>
              <div className="media-body">
                <h3 className="media-heading">Sem custos</h3>
                Aplicativo gratuito.
              </div>
            </div>
            <div className="media text-right feature">
              <span className="pull-right">
                <i className="fas fa-utensils fa-2x" />
              </span>
              <div className="media-body">
                <h3 className="media-heading">Restaurantes</h3>
                Verifique os melhores restaurantes na sua região.
              </div>
            </div>
            <div className="media text-right feature">
              <span className="pull-right">
                <i className="fa fa-question fa-2x" />
              </span>
              <div className="media-body">
                <h3 className="media-heading">Delivery*</h3>
                Peça comida no conforto do seu lar.
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <img
              src="/home/img/home.png"
              className="img-responsive active"
              alt=""
            />
          </div>
          <div className="col-md-4 col-sm-4 features" style={{ marginTop: 80 }}>
            <div className="media feature">
              <span className="pull-left">
                <i className="fas fa-mobile-alt fa-2x" />
              </span>
              <div className="media-body">
                <h3 className="media-heading">Chame o Garçom</h3>
                Chame o garçom utilizando seu celular.
              </div>
            </div>
            <div className="media feature">
              <span className="pull-left">
                <i className="fa fa-bullhorn fa-2x" />
              </span>
              <div className="media-body">
                <h3 className="media-heading">Fila de espera</h3>
                Conecte-se e aguarde ser chamado.
              </div>
            </div>
            <div className="media feature">
              <span className="pull-left">
                <i className="fa fa-bars fa-2x" />
              </span>
              <div className="media-body">
                <h3 className="media-heading">Menu Digital*</h3>
                Verifique o cardápio do estabelecimento e faça seu pedido
              </div>
            </div>
            <div className="media feature">
              <span className="pull-left">
                <i className="fas fa-mobile-alt fa-2x" />
              </span>
              <div className="media-body">
                <h3 className="media-heading">Acompanhamento de Pedido*</h3>
                Acompanhe o status do seu pedido no seu celular.
              </div>
            </div>
            <div className="media feature">
              <span className="pull-left">
                <i className="fa fa-plus fa-2x" />
              </span>
              <div className="media-body">
                <h3 className="media-heading">E muito mais!</h3>
                Estamos sempre adicionando novo recursos, cadastre-se{" "}
                <a href="/register">aqui</a> e receba novidades.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MoreFeatures;
