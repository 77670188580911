import React, { Component } from "react";

import ls from "../../../Localization";

const initialState = {
  name: "",
  email: "",
  subject: "",
  message: ""
};

export class Contact extends Component {
  state = {
    ...initialState,
    sendSuccess: false,
    sendFailed: false
  };

  handleChange = (name, value) => this.setState({ [name]: value });

  handleSend = () => {
    const { name, email, subject, message } = this.state;

    fetch("/api/home/sendmessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name,
        email,
        subject,
        message
      })
    }).then(res => {
      if (res.ok) this.setState({ sendSuccess: true });
      else this.setState({ sendFailed: true });
    });
  };

  render() {
    const {
      name,
      email,
      subject,
      message,
      sendSuccess,
      sendFailed
    } = this.state;

    return (
      <section className="section" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h3
                className="title"
                style={{
                  fontSize: "5rem",
                  color: "#00ca6e"
                }}
              >
                Contato
              </h3>
              <p className="text-muted sub-title">
                Para mais informações, contate-nos.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-6 col-sm-offset-3">
                  {sendSuccess && (
                    <div
                      id="success-email-js"
                      className="alert alert-success"
                      role="alert"
                    >
                      {ls.sendMessageSuccess}
                    </div>
                  )}
                  {sendFailed && (
                    <div
                      id="error-email-js"
                      className="alert alert-danger"
                      role="alert"
                    >
                      {ls.sendMessageError}
                    </div>
                  )}
                </div>
                <div className="col-md-7 col-sm-7">
                  <form>
                    <div className="form-group">
                      <input
                        id="name"
                        value={name}
                        onChange={e =>
                          this.handleChange("name", e.target.value)
                        }
                        type="text"
                        className="form-control"
                        placeholder={ls.name}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        id="email"
                        value={email}
                        onChange={e =>
                          this.handleChange("email", e.target.value)
                        }
                        type="email"
                        className="form-control"
                        placeholder={ls.email}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        id="subject"
                        value={subject}
                        onChange={e =>
                          this.handleChange("subject", e.target.value)
                        }
                        type="text"
                        className="form-control"
                        placeholder={ls.subject}
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        id="message"
                        value={message}
                        onChange={e =>
                          this.handleChange("message", e.target.value)
                        }
                        cols="30"
                        rows="10"
                        className="form-control"
                      />
                    </div>
                    <button
                      className="btn btn-default send-message-js col-sm-4"
                      type="button"
                      onClick={this.handleSend}
                    >
                      {ls.sendMessage}
                    </button>
                  </form>
                </div>
                <div
                  className="col-md-5 col-sm-5 contact-details"
                  style={{ color: "black" }}
                >
                  <div className="media">
                    <span className="pull-left">
                      <i
                        className="fas fa-map-marker-alt"
                        style={{ color: "#00ca6e" }}
                      />
                    </span>
                    <div className="media-body">
                      <h4 className="media-heading">
                        Rua 270, nº 275 - Sala 14 - Itapema - Brasil
                      </h4>
                    </div>
                  </div>
                  <div className="media">
                    <span className="pull-left" style={{ color: "#00ca6e" }}>
                      <i className="far fa-envelope" />
                    </span>
                    <div className="media-body">
                      <h4 className="media-heading">
                        <a
                          href="mailto:contato@connecty.me"
                          style={{ color: "black" }}
                        >
                          contato@connecty.me
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div className="media">
                    <a className="pull-left" href="tel:554735143340">
                      <i
                        className="fas fa-phone"
                        style={{ color: "#00ca6e" }}
                      />
                    </a>
                    <div className="media-body">
                      <a className="pull-left" href="tel:554735143340">
                        <h4
                          className="media-heading"
                          style={{ color: "black" }}
                        >
                          +55 47 3514-3340
                        </h4>
                      </a>
                    </div>
                  </div>
                  <div className="media">
                    <a className="pull-left" href="tel:554735143340">
                      <i
                        className="fab fa-whatsapp"
                        style={{ color: "#00ca6e" }}
                      />
                    </a>
                    <div className="media-body">
                      <a
                        className="pull-left"
                        href="https://wa.me/5547992052220?text=Ol%C3%A1%2C%20estou%20interessado%20no%20sistema%20e%20aplicativo%20Connecty.Me"
                      >
                        <h4
                          className="media-heading"
                          style={{ color: "black" }}
                        >
                          +55 47 9 9205-2220
                        </h4>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
