import React from "react";

const Home = () => {
  return (
    <section className="home bg-img-1" id="home">
      <div className="bg-overlay" />
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="home-fullscreen">
              <div className="full-screen">
                <div className="home-wrapper home-wrapper-alt">
                  <h2 className="font-light text-white">
                    Disponível para Android e iPhone nas lojas oficiais
                  </h2>
                  <h4 className="text-white">
                    Connecty Me é um aplicativo que melhora sua experiência com
                    restaurantes utilizando tecnologias modernas, agilizando o
                    atendimento e proporcionando uma experiência única.
                  </h4>
                  <a href="#getapp" className="btn btn-white-bordered">
                    Baixe agora
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
