import React from "react";

const Features = () => {
  return (
    <section className="section" id="features">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h3 className="title">
              Connecty Me melhora sua experiência quando se trata de comida
            </h3>
            <p className="text-muted sub-title">
              Queremos que você coma bem, mas mais do que isso, queremos que sua
              experiência seja a melhor possível.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4">
            <div className="features-box">
              <i className="far fa-calendar" />
              <h4>Resersa de mesa</h4>
              <p className="text-muted">
                Utilize o Connecty Me para reservar um lugar no seu restaurante
                favorito.
              </p>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="features-box">
              <i className="fas fa-mobile-alt" />
              <h4>Chame o garçom</h4>
              <p className="text-muted">
                Você pode utilizar o Connecty Me para chamar o garçom ou pedir a
                conta.
              </p>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="features-box">
              <i className="fa fa-bars" />
              <h4>Menu Online</h4>
              <p className="text-muted">
                Com o Connecty Me você pode verificar os saborosos pratos do seu
                restaurante favorito direto no seu celular.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
